import React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UtilsJson } from "./../utils/UtilsJson";
function PageContainer() {
  const navigate = useNavigate();
  let UserDetails = localStorage.getItem("details");

  if (!UserDetails) {
    navigate("/");
    return "";
  } else UserDetails = JSON.parse(UserDetails);

  const registrationMenu = [
    {
      text: "Academy",
      path: "/list/academy",
      icon: "M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z",
    },
    {
      text: "Employee",
      path: "/list/employee",
      icon: "M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0",
    },
    {
      text: "Owner",
      path: "/list/owner",
      icon: "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
    },
    {
      text: "Vendor",
      path: "/list/vendor",
      icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01",
    }
  ];

  const settingMenu = [
    {
      text: "Country",
      path: "/list/country",
      icon: "M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z",
    },
    {
      text: "State",
      path: "/list/state",
      icon: "M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0",
    },
    {
      text: "City",
      path: "/list/city",
      icon: "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
    },
    {
      text: "Employee Category",
      path: "/list/category",
      icon: "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
    },
    {
      text: "Academy Category",
      path: "/list/academy_category",
      icon: "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
    }
  ];

  return (
    <div className="hidden sm:col-span-2 sm:flex flex-col justify-between h-full bg-primary-900 text-slate-200">
      <div className="space-y-3">
        <div className="flex items-center justify-between p-3 bg-cyan-600">
          <h2 className="w-full text-center">Indian Master</h2>
        </div>
        <div className="flex-1 px-3">
          <ul className="pb-4 space-y-1 text-sm">
            <li
              key={Math.random().toString().slice(2)}
              className="rounded-sm"
            >
              <NavLink
                to={`${UtilsJson.routingUrl}/dashboard`}
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <svg
                  className="w-6 h-6 stroke-current"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    className="text-[#94a3b8]"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={`M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6`}
                  />
                </svg>
                <span>{`Dashboard`}</span>
              </NavLink>
            </li>

            <li
              key={Math.random().toString().slice(2)}
              className="rounded-sm"
            >
              <NavLink
                to={`${UtilsJson.routingUrl}/list/online`}
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <svg
                  className="w-6 h-6 stroke-current"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    className="text-[#94a3b8]"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={`M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z`}
                  />
                  <path
                    className="text-[#94a3b8]"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={`M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z`}
                  />
                </svg>
                <span>{`Online Academy`}</span>
              </NavLink>
            </li>
            <li
              key={Math.random().toString().slice(2)}
              className="rounded-sm"
            >
              <NavLink
                to={`${UtilsJson.routingUrl}/list/offline`}
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <svg
                  className="w-6 h-6 stroke-current"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    className="text-[#94a3b8]"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={`M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z`}
                  />
                </svg>
                <span>{`Offline Academy`}</span>
              </NavLink>
            </li>
            <li
              key={Math.random().toString().slice(2)}
              className="rounded-sm"
            >
              <NavLink
                to={`${UtilsJson.routingUrl}/list/needmore`}
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <svg
                  className="w-6 h-6 stroke-current"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    className="text-[#94a3b8]"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={`M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z`}
                  />
                </svg>
                <span>{`Need More`}</span>
              </NavLink>
            </li>

            <p className="p-2 font-light text-xs text-slate-300">Registration</p>
            {registrationMenu.map((m) => (
              <li
                key={Math.random().toString().slice(2)}
                className="rounded-sm border-b border-primary-800 pb-2"
              >
                <NavLink
                  to={`${UtilsJson.routingUrl}${m.path}`}
                  className="flex items-center px-2 py-1 space-x-3 rounded-md"
                >
                  {/* <svg
                    className="w-6 h-6 stroke-current"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      className="text-[#94a3b8]"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d={m.icon}
                    />
                  </svg> */}
                  <span className="text-xs font-normal">{m.text} </span>
                </NavLink>
              </li>
            ))}

            <p className="p-2 font-light text-xs text-slate-300">Setting</p>
            {settingMenu.map((m) => (
              <li
                key={Math.random().toString().slice(2)}
                className="rounded-sm border-b border-primary-800 pb-2"
              >
                <NavLink
                  to={`${UtilsJson.routingUrl}${m.path}`}
                  className="flex items-center px-2 py-1 space-x-3 rounded-md"
                >
                  {/* <svg
                    className="w-6 h-6 stroke-current"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      className="text-[#94a3b8]"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d={m.icon}
                    />
                  </svg> */}
                  <span className="text-xs font-normal">{m.text} </span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="bottom-0 flex items-center p-2 mt-2 space-x-4 justify-self-end">

        <div className="flex items-center w-full ">
          <NavLink
            to={'/'}
            className="text-xxl text-slate-400 cursor-pointer border border-red-500 w-full p-3 hover:bg-red-700 justify-center items-center text-center hover:text-white rounded-sm"
          >
            LogOut
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default PageContainer;
