export const FormFieldJson = {
  vendor: [
    {
      type: "text",
      title: "Name",
      name: "unavu_vendor_name",
      values: "",
      class: "col-span-3",
      require: true,
    },
    {
      type: "number",
      title: "Phone No",
      name: "unavu_vendor_mobile",
      values: "",
      class: "col-span-3",
      require: true,
    },
    {
      type: "email",
      title: "Email",
      name: "unavu_vendor_mail",
      values: "",
      class: "col-span-3",
      require: true,
    }, {
      type: "text",
      title: "Company Name",
      name: "unavu_vendor_cname",
      values: "",
      class: "col-span-3",
      require: true,
    }, {
      type: "textarea",
      title: "Product Details",
      name: "unavu_vendor_pdetailsd",
      values: "",
      class: "col-span-3",
      require: true,
    }, {
      type: "textarea",
      title: "Address",
      name: "address",
      values: "",
      class: "col-span-3",
      require: true,
    }, {
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        }, {
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ],
  academy: [
    {
      type: "text",
      title: "Name",
      name: "unavu_academy_name",
      values: "",
      class: "col-span-3",
      require: false,
    }, {
      type: "number",
      title: "Phone No",
      name: "unavu_academy_mobile",
      values: "",
      class: "col-span-3",
      require: false,
    },
    {
      type: "email",
      title: "Email",
      name: "unavu_academy_email",
      values: "",
      class: "col-span-3",
      require: false,
    }, {
      type: "textarea",
      title: "Address",
      name: "unavu_academy_address",
      values: "",
      class: "col-span-3",
      require: true,
    },
    {
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        }, {
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ],
  employee: [
    {
      type: "text",
      title: "Name",
      name: "unavu_employee_name",
      values: "",
      class: "col-span-3",
      require: true,
    }, {
      type: "date",
      title: "DOB",
      name: "unavu_employee_dob",
      values: "",
      class: "col-span-3",
      require: true,
    },
    {
      type: "select",
      title: "Gender",
      name: "unavu_employee_gender",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Male",
          value: 'male',
        }, {
          label: "Female",
          value: 'female',
        }, {
          label: "TransGender",
          value: 'transgender',
        },
      ],
    }, {
      type: "number",
      title: "Phone No",
      name: "unavu_employee_mobile",
      values: "",
      class: "col-span-3",
      require: true,
    }, {
      type: "textarea",
      title: "Address",
      name: "unavu_employee_address",
      values: "",
      class: "col-span-3",
      require: true,
    },
    {
      type: "select",
      title: "Category",
      name: "unavu_employee_category",
      values: "",
      class: "col-span-3",
      require: true,
      server: true,
      list: [],
      listName: "unavu_category",
    }, {
      type: "select",
      title: "City",
      name: "unavu_employee_city",
      values: "",
      class: "col-span-3",
      require: true,
      server: true,
      list: [],
      listName: "unavu_city",
    },
    {
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        }, {
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ],
  owner: [{
    type: "text",
    title: "Company Name",
    name: "unavu_employer_cname",
    values: "",
    class: "col-span-3",
    require: true,
  }, {
    type: "text",
    title: "Owner Name",
    name: "unavu_employer_oname",
    values: "",
    class: "col-span-3",
    require: true,
  }, {
    type: "number",
    title: "Mobile Number",
    name: "unavu_employer_mobile",
    values: "",
    class: "col-span-3",
    require: true,
  }, {
    type: "select",
    title: "City",
    name: "unavu_employer_city",
    values: "",
    class: "col-span-3",
    require: true,
    server: true,
    list: [],
    listName: "unavu_city",
  }, {
    type: "textarea",
    title: "Address",
    name: "unavu_employer_address",
    values: "",
    class: "col-span-3",
    require: true,
  }, {
    type: "select",
    title: "Status",
    name: "status",
    values: "",
    class: "col-span-3",
    require: true,
    server: false,
    list: [
      {
        label: "Active",
        value: '1',
      }, {
        label: "Inactive",
        value: '0',
      },
    ],
  },
  ], country: [
    {
      type: "text",
      title: "Name",
      name: "name",
      values: "",
      class: "col-span-3",
      require: true,
    }, {
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        }, {
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ], state: [
    {
      type: "text",
      title: "Name",
      name: "name",
      values: "",
      class: "col-span-3",
      require: true,
    }, {
      type: "select",
      title: "County",
      name: "country_id",
      values: "",
      class: "col-span-3",
      require: true,
      server: true,
      list: [],
      listName: "unavu_country"
    }, {
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        }, {
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ], city: [
    {
      type: "text",
      title: "Name",
      name: "name",
      values: "",
      class: "col-span-3",
      require: true,
    }, {
      type: "select",
      title: "State",
      name: "state_id",
      values: "",
      class: "col-span-3",
      require: true,
      server: true,
      list: [],
      listName: "unavu_state"
    }, {
      type: "select",
      title: "County",
      name: "country_id",
      values: "",
      class: "col-span-3",
      require: true,
      server: true,
      list: [],
      listName: "unavu_country"
    }, {
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        }, {
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ], academy_category: [
    {
      type: "text",
      title: "Name",
      name: "name",
      values: "",
      class: "col-span-3",
      require: true,
    }, {
      type: "file",
      title: "Image",
      name: "unavu_category_image",
      values: "",
      class: "col-span-6",
      require: true,
    }, {
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        }, {
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ], category: [
    {
      type: "text",
      title: "Name",
      name: "name",
      values: "",
      class: "col-span-3",
      require: true,
    }, {
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        }, {
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ], online: [
    {
      type: "text",
      title: "Name",
      name: "unavu_online_name",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "number",
      title: "Fees",
      name: "unavu_online_fees",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "text",
      title: "Duration",
      name: "unavu_online_duration",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "select",
      title: "Category",
      name: "unavu_online_catid",
      values: "",
      class: "col-span-3",
      require: true,
      server: true,
      list: [],
      listName: "unavu_academy_category"
    },{
      type: "textarea",
      title: "Short Description",
      name: "unavu_online_shortdes",
      values: "",
      class: "col-span-6",
      require: true,
    },{
      type: "textarea",
      title: "Long Description",
      name: "unavu_online_longdes",
      values: "",
      class: "col-span-6",
      require: true,
    },{
      type: "textarea",
      title: "Points",
      name: "unavu_online_points",
      values: "",
      class: "col-span-12",
      require: true,
    }, {
      type: "file",
      title: "Image",
      name: "unavu_online_images",
      values: "",
      class: "col-span-6",
      require: true,
    },{
      type: "file",
      title: "Video",
      name: "unavu_online_vd",
      values: "",
      class: "col-span-6",
      require: true,
    }, {
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        }, {
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ], offline: [
    {
      type: "text",
      title: "Name",
      name: "unavu_offline_name",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "number",
      title: "Fees",
      name: "unavu_offline_fees",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "text",
      title: "Duration",
      name: "unavu_offline_duration",
      values: "",
      class: "col-span-3",
      require: true,
    },{
      type: "select",
      title: "Category",
      name: "unavu_offline_catid",
      values: "",
      class: "col-span-3",
      require: true,
      server: true,
      list: [],
      listName: "unavu_academy_category"
    },{
      type: "textarea",
      title: "Short Description",
      name: "unavu_offline_shortdes",
      values: "",
      class: "col-span-6",
      require: true,
    },{
      type: "textarea",
      title: "Long Description",
      name: "unavu_offline_longdes",
      values: "",
      class: "col-span-6",
      require: true,
    },{
      type: "textarea",
      title: "Points",
      name: "unavu_offline_points",
      values: "",
      class: "col-span-6",
      require: true,
    }, {
      type: "file",
      title: "Image",
      name: "unavu_offline_images",
      values: "",
      class: "col-span-6",
      require: true,
    }, {
      type: "select",
      title: "Status",
      name: "status",
      values: "",
      class: "col-span-3",
      require: true,
      server: false,
      list: [
        {
          label: "Active",
          value: '1',
        }, {
          label: "Inactive",
          value: '0',
        },
      ],
    },
  ],
};
