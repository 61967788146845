export const TableJsonHeaderList = {
  academy: [
    {
      Header: "Id",
      accessor: "id",
      className: "px-6 py-2 whitespace-nowrap text-slate-900 text-sm",
    }, {
      Header: "Name",
      accessor: "unavu_academy_name",
    }, {
      Header: "Mobile Number",
      accessor: "unavu_academy_mobile",
    },
    {
      Header: "Email",
      accessor: "unavu_academy_email",
    }, {
      Header: "Address",
      accessor: "unavu_academy_address",
    }, {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Active
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ],
  owner: [
    {
      Header: "ID",
      accessor: "id",
      className: "px-6 py-2 whitespace-nowrap text-slate-900 text-sm",
    },
    {
      Header: "Name",
      accessor: "unavu_employer_cname",
    }, {
      Header: "Company Name",
      accessor: "unavu_employer_oname",
    },
    {
      Header: "Mobile",
      accessor: "unavu_employer_mobile",
    }, {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Active
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ],
  employee: [
    {
      Header: "Id",
      accessor: "id",
      className: "px-6 py-2 whitespace-nowrap text-slate-900 text-sm",
    },
    {
      Header: "Name",
      accessor: "unavu_employee_name",
    },
    {
      Header: "Phone Number",
      accessor: "unavu_employee_mobile",
    }, {
      Header: "Phone Number",
      accessor: "unavu_employee_gender",
    }, {
      Header: "Address",
      accessor: "unavu_employee_address",
    }, {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Active
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ],
  admin: [
    {
      Header: "ID",
      accessor: "id",
      className: "px-6 py-2 whitespace-nowrap text-slate-900 text-sm",
      Cell: ({ cell }) => cell.row.values.id ? (
        <h1 className="text-bold">{cell.row.values.id}</h1>
      ) : ""

    }, {
      Header: "Name",
      accessor: "name",
    }, {
      Header: "User Name",
      accessor: "username",
    }, {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Active
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ],
  vendor: [
    {
      Header: "Id",
      accessor: "id",
      className:
        "px-6 py-2 whitespace-nowrap text-slate-900 text-sm cursor-pointer",
    },
    {
      Header: "Name",
      accessor: "unavu_vendor_name",
    }, {
      Header: "Mobile",
      accessor: "unavu_vendor_mobile",
    }, {
      Header: "Email",
      accessor: "unavu_vendor_mail",
    },
    {
      Header: "Visible",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Visible
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ], country: [
    {
      Header: "Id",
      accessor: "id",
      className:
        "px-6 py-2 whitespace-nowrap text-slate-900 text-sm cursor-pointer",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Visible",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Visible
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ], state: [
    {
      Header: "Id",
      accessor: "id",
      className:
        "px-6 py-2 whitespace-nowrap text-slate-900 text-sm cursor-pointer",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Visible",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Visible
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ],city: [
    {
      Header: "Id",
      accessor: "id",
      className:
        "px-6 py-2 whitespace-nowrap text-slate-900 text-sm cursor-pointer",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Visible",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Visible
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ],academy_category: [
    {
      Header: "Id",
      accessor: "id",
      className:
        "px-6 py-2 whitespace-nowrap text-slate-900 text-sm cursor-pointer",
    },
    {
      Header: "Category Name",
      accessor: "name",
    },
    {
      Header: "Visible",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Visible
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ],category: [
    {
      Header: "Id",
      accessor: "id",
      className:
        "px-6 py-2 whitespace-nowrap text-slate-900 text-sm cursor-pointer",
    },
    {
      Header: "Category Name",
      accessor: "name",
    },
    {
      Header: "Visible",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Visible
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ],online: [
    {
      Header: "Id",
      accessor: "id",
      className:
        "px-6 py-2 whitespace-nowrap text-slate-900 text-sm cursor-pointer",
    },
    {
      Header: "Name",
      accessor: "unavu_online_name",
    },{
      Header: "Short Desc",
      accessor: "unavu_online_shortdes",
    },{
      Header: "Fees",
      accessor: "unavu_online_fees",
    },
    {
      Header: "Visible",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Visible
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ],offline: [
    {
      Header: "Id",
      accessor: "id",
      className:
        "px-6 py-2 whitespace-nowrap text-slate-900 text-sm cursor-pointer",
    },
    {
      Header: "Name",
      accessor: "unavu_offline_name",
    },{
      Header: "Short Desc",
      accessor: "unavu_offline_shortdes",
    },{
      Header: "Fees",
      accessor: "unavu_offline_fees",
    },
    {
      Header: "Visible",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status == 1 ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Visible
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Inactive
          </span>
        ),
    },
  ],needmore: [
    {
      Header: "Id",
      accessor: "id",
      className:
        "px-6 py-2 whitespace-nowrap text-slate-900 text-sm cursor-pointer",
    },
    {
      Header: "Name",
      accessor: "unavu_employer_oname",
    },{
      Header: "Catergory Name",
      accessor: "name",
    },{
      Header: "City",
      accessor: "city_name",
    },{
      Header: "Phone Number",
      accessor: "unavu_employer_mobile",
    },{
      Header: "Plane Name",
      accessor: "unavu_plan_name",
    },
    {
      Header: "Visible",
      accessor: "status",
      Cell: ({ cell }) =>
        cell.row.values.status != '1' ? (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
            Processed
          </span>
        ) : (
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            Not Processed
          </span>
        ),
    },
  ]

};
