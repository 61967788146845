import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  TrashIcon,
  PencilAltIcon,
  UserIcon,
  UserAddIcon,
} from "@heroicons/react/outline";
import { TableJsonHeaderList } from "../JSON/TableJson";

import PageContainer from "./PageContainer";
import PostApi from "../Services/PostApi";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className=" text-slate-600">
      Search{" "}
      <input
        className="mt-1h-8 shadow-sm h-8 px-3 rounded sm:text-sm border border-slate-300 hover:border-slate-500 outline-none"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records`}
      />
    </div>
  );
}

function TableList(props) {
  const { type } = useParams();
  const [data, setData] = useState([]);
  let tableData = { "list_key": "Mastertable", "label": "unavu_" + type, "select": "*" }
  const [ModalPopUpFlag, setModalPopUpFlag] = useState("hidden");
  const [UserListModalPopUpFlag, setUserListModalPopUpFlag] = useState("hidden");
  const [NoNUserListModalPopUpFlag, setNoNUserListModalPopUpFlag] = useState("hidden");
  const [deleteCurrent, setDeleteCurrent] = useState();
  const [userList, setuserList] = useState([]);
  const [nonuserList, setnonuserList] = useState([]);
  var appendList = [];
  const [selectedRow, setSelectedRow] = useState([]);
  function ClosePopUp() {
    setModalPopUpFlag("hidden");
    setUserListModalPopUpFlag("hidden");
    setNoNUserListModalPopUpFlag("hidden");
    setDeleteCurrent([]);
  }
  async function setTableData() {
    props.setPageLoader(true);
    if (type === 'needmore')
      tableData = { "list_key": "GetNeedMre" };
    let { responcePostData } = await PostApi('services.php', tableData)
    if (responcePostData) {
      props.setPageLoader(false);
    }
    setData(responcePostData.data.result);
  }
  useEffect(() => {
    setTableData();
  }, [type])

  const TableColumn = TableJsonHeaderList[type];
  const columns = React.useMemo(
    () => (TableColumn ? TableColumn : []),
    [TableColumn]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  function DeleteData(params) {
    setModalPopUpFlag("");
    setDeleteCurrent(params);
  }

  function UserListData(params) {
    console.log(params.original)
    let tableData = { "list_key": "GetPurchase", "unavu_purchase_id": params?.original?.unavu_needmore_purchaseid };
    PostApi('services.php', tableData).then((e) => {
      console.log(e?.responcePostData?.data?.result);
      setuserList(e?.responcePostData?.data?.result || []);
      setUserListModalPopUpFlag("");
    });
  }


  function GetNonlistEmp(params) {
    console.log(params);
    setSelectedRow(params);
    let tableData = {
      "list_key": "GetNonlistEmp",
      "unavu_needmore_purchaseid": params.original.unavu_needmore_purchaseid,
      "unavu_needmore_cityid": params.original.unavu_needmore_cityid,
      "unavu_needmore_catid": params.original.unavu_needmore_catid
    };

    PostApi('services.php', tableData).then((e) => {
      setnonuserList(e.responcePostData.data.result);
      setNoNUserListModalPopUpFlag("");
    });

  }

  function DeleteRequest() {
    const deleteId = deleteCurrent.original.id;
    PostApi('services.php', { "list_key": "UpdateMaster", "label": "unavu_" + type, "update_coloum": { 'status': 0 }, "condition": { "id": deleteId } },
      props,
      "Record Deleted Sucessfully"
    ).then(() => {
      window.location.reload(false);
    })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        ClosePopUp();
      });
  }

  function rowAppend(user, type) {
    console.log(user, type.target.checked);
    var data = {
      list_status: "1",
      unavu_listemp_employer_id: selectedRow.original.unavu_needmore_empid,
      unavu_listemp_employee_id: user.id,
      unavu_listemp_purchase_id: selectedRow.original.unavu_needmore_planid
    }


    if (type.target.checked) 
      appendList.push(data)
    else 
      appendList = appendList.filter((i) => i.unavu_listemp_employee_id === data.unavu_listemp_employee_id)
    
    console.log(appendList);
  }


  function appendUser(){
    PostApi('services.php', { "list_key": "AddBulkMaster", "tablename":"unavu_listemp","tablefields":appendList},
      props,
      "Record Deleted Sucessfully"
    ).then(() => {
     // window.location.reload(false);
    })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        ClosePopUp();
      });
  }

  function DeleteModal() {
    return (
      <div
        className={`min-w-screen h-screen animated fadeIn faster  fixed bg-slate-400 bg-opacity-75  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover ${ModalPopUpFlag}`}
      >
        <div className="flex">
          <div className="bg-white rounded-xl shadow-lg justify-center">
            <div className="text-center p-5 flex-auto justify-center">
              <h2 className="text-xl font-bold py-4 ">Are you sure?</h2>
              <p className="text-sm text-gray-500 px-8">
                Do you really want to delete? This process cannot be undone.
              </p>
            </div>
            <div className="p-3 mt-2 text-center space-x-4 md:block">
              <button
                onClick={() => ClosePopUp()}
                className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"
              >
                Cancel
              </button>
              <button
                onClick={() => DeleteRequest()}
                className="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function UserListModal() {
    return (
      <div
        className={`min-w-screen h-screen animated fadeIn faster  fixed bg-slate-400 bg-opacity-75  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover ${UserListModalPopUpFlag}`}
      >
        <div className="flex">
          <div className="bg-white rounded-xl shadow-lg justify-center">
            <div className="p-5 flex-auto justify-center">
              <h2 className="text-center text-xl font-bold py-4 ">Listed User List  - {userList?.length || 0} </h2>
              <div className="relative overflow-x-hidden h-max-[60vh] overflow-y-auto">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        S.NO
                      </th>
                      <th scope="col" className="px-6 py-3">
                        User name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Phone Number
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      userList.length ?
                        userList.map(({ unavu_employee_name, unavu_employee_mobile }, i) => (
                          <tr key={i} className="bg-white border-b text-gray-900">
                            <td className="px-6 py-1 font-medium  whitespace-nowrap ">
                              {++i}
                            </td>
                            <td className="px-6 py-1 font-medium  whitespace-nowrap ">
                              {unavu_employee_name}
                            </td>
                            <td className="px-6 py-1 font-bold">
                              {unavu_employee_mobile}
                            </td>
                          </tr>
                        )) : <tr className="bg-white border-b text-gray-900 text-center">
                          <td colSpan={3} className="p-3">No record found</td>
                        </tr>
                    }
                  </tbody>
                </table>
              </div>

            </div>
            <div className="p-3 mt-2 text-center space-x-4 md:block">
              <button
                onClick={() => ClosePopUp()}
                className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"
              >
                Close
              </button>

            </div>
          </div>
        </div>
      </div>
    );
  }

  function GetNonlistEmpModal() {
    return (
      <div
        className={`min-w-screen h-screen animated fadeIn faster fixed bg-slate-400 bg-opacity-75  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover ${NoNUserListModalPopUpFlag}`}
      >
        <div className="flex">
          <div className="bg-white rounded-xl shadow-lg justify-center">
            <div className="p-5 flex-auto justify-center">
              <h5 className="text-center text-md font-bold py-4 ">Not Listed User List - {nonuserList.length} </h5>
              <div className="relative overflow-x-hidden h-[60vh] overflow-y-auto">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                    <tr>
                      <th scope="col" className="px-6 py-1">

                      </th>
                      <th scope="col" className="px-6 py-1">
                        S.NO
                      </th>
                      <th scope="col" className="px-6 py-1">
                        User name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Phone Number
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      nonuserList?.length ?
                        nonuserList.map((value, i) => (
                          <tr className="bg-white border-b text-gray-900">
                            <th scope="row" className="px-6 py-1 font-medium  whitespace-nowrap ">
                              <input id="checkbox" onClick={(e) => rowAppend(value, e)} type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            </th>
                            <th scope="row" className="px-6 py-1 font-medium  whitespace-nowrap ">
                              {++i}
                            </th>
                            <th scope="row" className="px-6 py-1 font-medium  whitespace-nowrap ">
                              {value?.unavu_employee_name}
                            </th>
                            <td className="px-6 py-1">
                              {value?.unavu_employee_mobile}
                            </td>
                          </tr>
                        )) : ''
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="p-3 mt-2 text-center space-x-4 md:block">
              <button
                onClick={() => appendUser()}
                className="mb-2 md:mb-0 bg-blue-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-white rounded-full hover:shadow-lg hover:bg-blue-800"
              >
                Send
              </button>
              <button
                onClick={() => ClosePopUp()}
                className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen overflow-auto bg-gold-100 grid grid-cols-12 bg-slate-200  outline-none">
      <PageContainer></PageContainer>
      <div className="col-span-12 sm:col-span-10  m-5">
        <div className="flex justify-between flex-wrap">
          <div className="text-primary-900 text-3xl font-bold capitalize">
            <h1>{type.replace("_", " ")} ✨ </h1>
          </div>
          {type === "settings" || type === "order" ? (
            ""
          ) : (
            <NavLink key={Math.random()} to={"new"}>
              <button className="px-6  py-2.5  bg-blue-600  text-white  font-medium  text-xs  leading-tight  uppercase  shadow-md  hover:bg-blue-700 hover:shadow-lg  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0  active:bg-blue-800 active:shadow-lg  transition  duration-150  ease-in-out">
                Add New
              </button>
            </NavLink>
          )}
        </div>
        <div className="bg-white shadow-lg rounded-sm border border-gray-200 mt-5 overflow-auto">
          <header className="px-5 py-4 border-b border-gray-100 p-4 flex flex-wrap justify-between ">
            <h2 className="text-gray-800 text-base font-semibold justify-items-start capitalize">
              All {type.replace("_", " ")}
              <span className="text-base font-semibold text-slate-500">
                {" - " + data.length}
              </span>
            </h2>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </header>
          <table
            className="w-full divide-y divide-gold-600"
            {...getTableProps()}
          >
            <thead className="bg-slate-200 text-slate-500">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                  <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              ))}
            </thead>
            <tbody
              className="bg-white divide-y divide-gray-200"
              {...getTableBodyProps()}
            >
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className="px-6 py-2 whitespace-nowrap text-slate-500 text-sm "
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                    <td className="px-6 py-2 whitespace-nowrap text-slate-500 text-sm  flex flex-row">
                      {type !== 'needmore' ?
                        <>
                          <NavLink
                            key={Math.random()}
                            to={
                              row.original.id
                            }
                          >
                            <PencilAltIcon
                              height={15}
                              className=" text-blue-500 cursor-pointer text-left mx-2"
                            ></PencilAltIcon>
                          </NavLink>

                          <TrashIcon
                            height={15}
                            className=" text-red-500 cursor-pointer text-left"
                            onClick={() => {
                              DeleteData(row);
                            }}
                            value={""}
                          ></TrashIcon>



                        </>
                        : <>

                          <UserIcon height={15}
                            className=" text-blue-500 cursor-pointer text-left mx-2"
                            onClick={() => {
                              UserListData(row);
                            }}></UserIcon>
                          <UserAddIcon height={15}
                            className=" text-green-500 cursor-pointer text-left"
                            onClick={() => {
                              GetNonlistEmp(row);
                            }}></UserAddIcon>
                        </>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <div
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                href="#"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Previous
              </div>
              <div
                onClick={() => nextPage()}
                disabled={!canNextPage}
                href="#"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Next
              </div>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing Page{" "}
                  <span className="font-medium">{pageIndex + 1}</span> of{" "}
                  <span className="font-medium">{pageOptions.length}</span>
                </p>
              </div>
              <div>
                <nav
                  className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                  aria-label="Pagination"
                >
                  <div
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                    href="#"
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronDoubleLeftIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                  <div
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    href="#"
                    className="relative inline-flex items-center px-2 py-2  border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </div>

                  <div
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    href="#"
                    className="relative inline-flex items-center px-2 py-2  border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <div
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                    href="#"
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronDoubleRightIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal></DeleteModal>
      <UserListModal></UserListModal>
      <GetNonlistEmpModal></GetNonlistEmpModal>
    </div>
  );
}

export default TableList;
