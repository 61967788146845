import React, { useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import PageContainer from "./Components/PageContainer";
import Dashboard from "./Components/Dashboard";
import TableList from "./Components/TableList";
import FormFields from "./Components/FormFields";
import LoginPage from "./Components/LoginPage";
import { UtilsJson } from "./utils/UtilsJson";
import { ColorRing } from 'react-loader-spinner'

function App() {
  const [pageLoader, setPageLoader] = useState(false);
  const [message, setMessage] = useState({
    class: "bg-blue-600",
    visable: false,
    title: "Error",
    body: "Please try again",
  });

  return (
    <Router>
      <div className={pageLoader ? 'flex justify-center absolute items-center w-full h-screen' : 'hidden'}>
        <div className="m-auto">          
          <ColorRing
            visible={pageLoader}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperclassName="blocks-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
          />
        </div>
      </div>
      <div style={{ 'right': '10px', 'top': '10px' }}
        className={`absolute top-10 right-10 ${message.visable ? "" : "hidden"
          }`}
      >
        <div
          className={`${message.class} m-2 shadow-lg max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block mb-3`}
          id="static-example"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          data-mdb-autohide="false"
        >
          <div
            className={`${message.class} flex justify-between items-center py-2 px-3 bg-clip-padding border-b border-slate-300 rounded-t-lg`}
          >
            <p className="font-bold text-white flex items-center">
              {message.title}
            </p>
            <div className="flex items-center">
              <button
                type="button"
                className="btn-close text-xs btn-close-white box-content  w-4 h-4 ml-2 text-white border-none rounded-none focus:shadow-none focus:outline-none focus:opacity-100 hover:text-white hover:opacity-75 hover:no-underline"
                data-mdb-dismiss="toast"
                aria-label="Close"
                onClick={() => setMessage({ ...message, visable: false })}
              >
                x
              </button>
            </div>
          </div>
          <div className="p-3 rounded-b-lg break-words text-white">
            {message.body}
          </div>
        </div>
      </div>

      <Routes>
        <Route path={`${UtilsJson.routingUrl}/`} element={<LoginPage setPageLoader={setPageLoader}
          pageLoader={pageLoader}
          setMessage={setMessage}
          message={message}></LoginPage>}></Route>
        <Route
          path={`${UtilsJson.routingUrl}/table`}
          element={
            <PageContainer
              setPageLoader={setPageLoader}
              pageLoader={pageLoader}
              setMessage={setMessage}
              message={message}
            />
          }
        />
        <Route
          path={`${UtilsJson.routingUrl}/list/:type`}
          element={
            <TableList
              setPageLoader={setPageLoader}
              pageLoader={pageLoader}
              setMessage={setMessage}
              message={message}
            />
          }
        />
        <Route
          path={`${UtilsJson.routingUrl}/list/:type/:id`}
          element={
            <FormFields
              setPageLoader={setPageLoader}
              pageLoader={pageLoader}
              setMessage={setMessage}
              message={message}
            />
          }
        />
        <Route path={`${UtilsJson.routingUrl}/dashboard`} element={<Dashboard />} />
        <Route path="*" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
