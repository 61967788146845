import { LockClosedIcon } from "@heroicons/react/solid";
import { useState } from "react";
import PostApi from "../Services/PostApi";
import Logos from "../utils/img/logo.png";
import { useNavigate } from "react-router-dom";
export default function LoginPage(props) {
  localStorage.clear();
  const intilizeValue = {};
  const [formValues, setFormValues] = useState(intilizeValue);
  const navigate = useNavigate();
  /* Set Values to form  */
  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  /*To save the form */
  const login = (e) => {
    e.preventDefault();
    let loginValues = {"list_key":"Mastertable","label":"unavu_admin","select":"*","condition":formValues,"limit":"1"};
    PostApi("services.php",loginValues,props, 'Login Sucessfully','login').then((e)=> {
      if(e?.responcePostData?.data?.result?.length){
        props.setMessage({class:'bg-green-600',visable:true, title:'Success', body:'Login Successfully'});
        localStorage.setItem('details',JSON.stringify(e.responcePostData.data.result[0]));
        navigate("/dashboard");
      }else
        props.setMessage({class:'bg-red-600',visable:true, title:'Error', body:'Invalid details'});      
    })
  };

  return (
    <div className="flex justify-center">
      <div className="min-h-full w-full h-screen bg-[conic-gradient(at_bottom_right,_var(--tw-gradient-stops))] from-blue-200 to-blue-600 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full p-10 bg-slate-100 space-y-8 rounded-xl">
          <div className="flex flex-col items-center">
            <img loading="lazy" src={Logos} className="w-24"/>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
             Admin Panel
            </h2>
          </div>
          <form
            className="mt-8 space-y-6"
            action="#"
            method="POST"
            onSubmit={login}
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="username" className="sr-only">
                  User Id
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Username"
                  onBlur={handlechange}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onBlur={handlechange}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div>
              <button
                type="submit" 
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                    aria-hidden="true"
                  />
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
