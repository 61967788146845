import React, { useEffect, useState } from 'react';
import PostApi from '../Services/PostApi';

const SelectBox = ({ name, setFormValues, formValues, server, optionsList, listName }) => {
    const [options, setOptions] = useState([]);
    const fetchData = async () => {
        try {
            let tableData = { "list_key": "Mastertable", "label": listName, "select": "*", "condition": { "status": 1 } };
            PostApi('services.php', tableData).then((e) => {
                const updatedSelectLists = e?.responcePostData?.data?.result;
                setOptions(updatedSelectLists);
            });
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (server)
            fetchData();
    }, []);

    /* Set Values to form  */
    const handlechange = (e) => {
        const { name, value } = e.target
        setFormValues({ ...formValues, [name]: value });
    };

    return (
        <select
            className="mt-1 h-8 shadow-sm px-2 rounded-sm text-slate-600 sm:text-sm border border-slate-300 hover:border-slate-500 outline-none w-full "
            value={formValues[name]}
            autoComplete="off"
            name={name}
            id={name}
            isSearchable="true"
            onChange={handlechange}
        >
            <option>Select from the list </option>
            {server ? options.map(item => (
                <option key={item.id} value={item.id} >
                    {item.name}
                </option>
            )) : optionsList.map(item => (
                <option key={item.value} value={item.value} >
                    {item.label}
                </option>
            ))}
        </select>
    );
};

export default SelectBox;
